import React from 'react'
import { Header } from 'components/theme'
import { Container } from 'components/common'
import MarketingServices from 'assets/illustrations/marketing-services'
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'

export const Landing = () => (
	<Wrapper>
		<Header />
		<IntroWrapper as={Container}>
			<Details>
				<h1>Managed Marketing Services Creating Better Ways to Grow</h1>
				<p>We engage customers online with dynamic stories about your
				 business. Offering our clients full-stack and personalized marketing packages
				  to help grow traffic, convert more visitors, and run complete marketing campaigns at scale.
				</p>
			</Details>
			<Thumbnail>
				<MarketingServices alt="Endslate Marketing Services" />
			</Thumbnail>
		</IntroWrapper>
	</Wrapper>
)