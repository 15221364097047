import React from 'react'
import { Container } from 'components/common'
import MarketingAgency from 'assets/illustrations/marketing-agency'
import { Wrapper, SkillsWrapper, Details, Thumbnail, Button } from './styles'

export const Success = () => (
	<Wrapper>
		<SkillsWrapper as={Container}>
			<Thumbnail>
				<MarketingAgency alt="Digital Marketing Agency" />
			</Thumbnail>
			<Details>
				<h1>Start Growing With Endslate</h1>
				<p>Our team strives to provide customers with 
                transperancy into our services, making every part of the process
                 more human. Getting started with Digital Marketing has never been easier.
				</p>
				<div>
					<a href="mailto:contact@endslate.co" target="_blank" rel="noopener noreferrer nofollow">
						<Button>Contact Us</Button>
					</a>
					<a href="tel:+14242405332" target="_blank" rel="noopener noreferrer nofollow">
						<Button>Call Today</Button>
					</a>
				</div>
			</Details>
		</SkillsWrapper>
	</Wrapper>
)