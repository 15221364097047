import React from 'react'
import { Layout, SEO } from 'components/common'
import { Landing, Service, Success } from 'components/marketing'

export default () => (
	<Layout>
		<SEO />
		<Landing />
		<Service />
		<Success />
	</Layout>
)