import React from 'react'
import { Container, Card } from 'components/common'
import brandingIcon from 'assets/icons/branding.svg'
import ecommerceStrategy from 'assets/icons/ecommerce.svg'
import webDesign from 'assets/icons/desktop.svg'
import socialMedia from 'assets/icons/social-media.svg'
import mediaBuying from 'assets/icons/paid-media.svg'
import contentMarketing from 'assets/icons/content-marketing.svg'
import { Wrapper, Grid, Item, Content } from './styles'

// import Button after sublevel pages <Button>Learn More</Button> 


export const Service = () => (
	<Wrapper as={Container}>
		<h2>Our Services</h2>
		<Grid>
			<Item>
				<Card>
					<Content>
						<h4>Brand Identity</h4>
						<img src={brandingIcon} alt="Brand Identity Services by Endslate" />
						<p>Through a series of interviews with our branding specialist,
						 we are able to enahnce our client's branding and create a truly unique digital experience.</p>
					</Content>
				</Card>
			</Item>
			<Item>
				<Card>
					<Content>
						<h4>eCommerce Strategy</h4>
						<img src={ecommerceStrategy} alt="eCommerce Strategy by Endslate" />
						<p>Whether it's giving your business a new storefront or refresh,
						we offer all clients tailored strategies, implementations and ongoing optimization.</p>
					</Content>
				</Card>
			</Item>
			<Item>
				<Card>
					<Content>
						<h4>Web Design</h4>
						<img src={webDesign} alt="Web Design Services by Endslate" />
						<p>We build mobile-ready and eCommerce friendly sites with beautiful
						on-brand designs that engage and move customers through the sales funnel.</p>
					</Content>
				</Card>
			</Item>
			<Item>
				<Card>
					<Content>
						<h4>Social Media</h4>
						<img src={socialMedia} alt="Social Media Management Services by Endslate" />
						<p>Building communities that resonate with audiences online and offline. 
						We ensure campaigns garner brand attention and traffic.</p>
					</Content>
				</Card>
			</Item>
			<Item>
				<Card>
					<Content>
						<h4>Media Buying</h4>
						<img src={mediaBuying} alt="Media Buying Strategy by Endslate" />
						<p>We ensure our client's campaigns are geared towards quality search intents,
						from potential customers to searchers with buying intent. </p>
					</Content>
				</Card>
			</Item>
			<Item>
				<Card>
					<Content>
						<h4>Content & Search Optimization</h4>
						<img src={contentMarketing} alt="Content Marketing Services by Endslate" />
						<p>Our team creates content that drives discovery and search positioning, synchronizing
						content and search strategy that generate more sales.</p>
					</Content>
				</Card>
			</Item>
		</Grid>
	</Wrapper>
)